import type { CookieRef } from '#app'

export const useConfirmCountry = () => {
  const confirmCountryCookie: CookieRef<boolean | undefined> =
    useCookie(`show_confirm_country`)

  const open = () => {
    confirmCountryCookie.value = true
  }

  const disable = () => {
    if (typeof confirmCountryCookie.value === 'boolean') {
      confirmCountryCookie.value = false
    }
  }
  const isOpen = computed(() => {
    return !!confirmCountryCookie.value
  })

  return {
    isOpen,
    disable,
    open,
  }
}
